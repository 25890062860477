<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="配置类型:" prop="Type">
              <Select clearable v-model="params.Type" placeholder="配置类型">
                <Option
                  v-for="item in SysConfigTypeEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="配置Key:" prop="Key">
              <Input placeholder="配置Key" v-model="params.Key" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="配置描述:" prop="Remark">
              <Input placeholder="配置描述" v-model="params.Remark" clearable />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button type="success" icon="md-add-circle" @click="handleAddNew">
            新增
          </Button>
          <Button
            type="warning"
            class="ml2"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="$importCache('AllSysConfig', $data)"
          >
            更新缓存
            <i>{{ cacheUpdatedTime }}</i>
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      @on-sort-change="sortChange"
      :data="resObj.DataList"
      highlight-row
      size="small"
    >
      <template #Action="{ row }">
        <Button
          size="small"
          type="success"
          v-if="$canAction(['Sys_Config_Edit'])"
          @click="handleOpenEdit(row)"
        >
          编辑
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>

    <Modal v-model="SysConfigView" :title="'配置信息'" class-name="i-modal">
      <Form
        ref="form"
        :model="selectedObj"
        :label-width="80"
        class="modelViewFromCheck"
      >
        <Row :gutter="16">
          <Col span="12">
            <FormItem label="配置类型:" prop="Type">
              <Select
                clearable
                :disabled="isEdit"
                v-model="selectedObj.Type"
                placeholder="配置类型"
              >
                <Option
                  v-for="item in SysConfigTypeEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="Key" label="配置KEY">
              <Input
                type="text"
                :disabled="isEdit"
                v-model="selectedObj.Key"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="24">
            <FormItem prop="Value" label="配置值">
              <Input type="textarea" v-model="selectedObj.Value"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="12">
            <FormItem label="状态:" prop="Status">
              <Select clearable v-model="selectedObj.Status" placeholder="状态">
                <Option key="10" :value="10"> 启用 </Option>
                <Option key="20" :value="20"> 禁用 </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="24">
            <FormItem prop="Remark" label="备注信息">
              <Input type="textarea" v-model="selectedObj.Remark"></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button @click="SysConfigView = false">取消</Button>
        <Button type="primary" @click="handleSave" :loading="SaveLoading">
          {{ !SaveLoading ? "保存" : "保存中..." }}
        </Button>
      </div>
    </Modal>
  </Layout>
</template>

<script>
import bizEnum from "@/libs/bizEnum";
import layout from "@/components/layout/index.vue";
import api from "@/api";
export default {
  name: "SysConfig",
  data() {
    return {
      tableLoading: false,
      tableHeight: 0,
      cacheUpdatedTime: "",
      isEdit: false,
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      SysConfigView: false,
      SaveLoading: false,
      selectedObj: {},
      params: {
        Type: "",
        Key: "",
        Remark: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Asc",
      },
      columns: [
        {
          title: "配置类型",
          key: "TypeCN",

          align: "center",
          sortable: "custom",
        },
        {
          title: "配置KEY",
          key: "Key",

          align: "left",
          sortable: "Key",
        },
        {
          title: "配置描述",
          key: "Remark",

          align: "left",
          sortable: "配置描述",
        },
        {
          title: "值",
          key: "Value",

          align: "left",
          sortable: "custom",
        },
        {
          title: "状态",
          key: "StatusCN",

          align: "center",
          sortable: "custom",
        },
      ],
    };
  },
  computed: {
    SysConfigTypeEnum() {
      return bizEnum.SysConfigTypeEnum;
    },
  },
  methods: {
    async loadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetSysConfigList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleAddNew() {
      this.$refs.form.resetFields();
      this.selectedObj = {};
      this.SysConfigView = true;
      this.isEdit = false;
    },
    handleOpenEdit(row) {
      this.$refs.form.resetFields();
      this.selectedObj = JSON.parse(JSON.stringify(row));
      this.SysConfigView = true;
      this.isEdit = true;
    },
    handleSave() {
      const _this = this;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.SaveLoading = true;
          try {
            const res = await api.SaveSysConfig(this.selectedObj);
            if (res.Status === 100) {
              this.SaveLoading = false;
              this.$Message.success("操作成功");
              // this.selectedObj = res.Data;
              this.SysConfigView = false;
              setTimeout(function () {
                _this.loadList();
              }, 800);
            } else {
              this.$Notice.error({
                title: "错误",
                desc: res.Msg,
              });
            }
          } catch (error) {
            console.log(error);
            this.$Notice.error({
              title: "错误",
              desc: error,
            });
            this.SaveLoading = false;
          }
        } else {
          this.$Message.error("信息填写有误");
        }
      });
    },
    async handleOpenDelete(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除【" + row.Key + "】吗？",
        loading: true,
        onOk: function () {
          _this.delete(row);
        },
      });
    },
    async delete(row) {
      try {
        const res = await api.DeleteSysConfig({
          id: row.ID,
        });
        if (res.Data) {
          row.Status = -99;
          row.StatusCN = "已删除";
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === row.ID
          );
          if (index >= 0) {
            this.resObj.DataList.splice(index, 1);
          }
          this.$Message.success("删除成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.loadList();
    this.$getCacheUpdatedTime("AllSysConfig", this);
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadList();
        this.$getCacheUpdatedTime("AllSysConfig", this);
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  components: { layout },
};
</script>
